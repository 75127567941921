import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Header from '@components/header/Header.js';

import * as chatActions from '@actions/chatActions';
import * as navigationActions from '@actions/navigationActions';
import * as notificationActions from '@actions/notificationActions';
import * as platformActions from '@actions/platformActions';
import * as settingsActions from '@actions/settingsActions';

class HeaderContainer extends PureComponent {
  render() {
    const { getNotifications, readNotifications, getChats, setTutorial, ...props } = this.props;
    const { user, notifications } = this.props;

    return (
      <Header
        {...props}
        setTutorial={(tutorial) => {
          setTutorial(tutorial);
        }}
        getNotifications={(from = 0, amount = 20) => getNotifications(from, amount)}
        readNotifications={() => {
          const unreadNotifications = [...notifications]
            .filter(
              (notification) => notification.read.filter((r) => r.by.id === user.id).length < 1
            )
            .map((notification) => notification.id);
          if (unreadNotifications.length > 0) readNotifications(unreadNotifications);
        }}
        getChats={(from = 0, amount = 20) => getChats(from, amount)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    stickyChat: state.settings.settings?.userInterface?.stickyChat || false,
    platform: state.platform.platform,
    platformIsFetching: state.platform.platformIsFetching,

    notificationsIsFetching: state.notification.notificationsIsFetching,
    notifications: state.notification.notifications,
    notificationsTotalResults: state.notification.notificationsTotalResults,
    notificationsUnread: state.notification.notificationsUnread,

    chats: state.chat.chats,
    chatsIsFetching: state.chat.chatsIsFetching,
    chatsTotalResults: state.chat.chatsTotalResults,

    unreadMessages: state.chat.unreadMessages,

    tutorial: state.settings.tutorial,
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (from, to) => dispatch(notificationActions.getNotifications(from, to)),
    readNotifications: (notifications) =>
      dispatch(notificationActions.readNotifications(notifications)),

    getChats: (from, amount) => dispatch(chatActions.getChats(from, amount)),

    getPlatformById: (id) => dispatch(platformActions.getPlatformById(id)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),

    setTutorial: (tutorial) => dispatch(settingsActions.setTutorial(tutorial)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(HeaderContainer));
